*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}
body {
  margin:4rem 0 ;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
section{
  min-height: 50vh;
}
.navbar{
  background-color: #121212;
  z-index: 2;
  position: fixed;
  width: 100%;
  top: 0;
  min-height: 4rem;
  color: white;
  display: flex;
  justify-content: end;
  align-items: center;
  border-bottom: 1px solid #F97300;
  /* margin-bottom: 2rem; */


}
.nav-items{
  margin-right: 3rem;
  display: flex;
  gap: 2rem;


}
.nav-item{
  cursor: pointer;
  border-bottom: 1px solid transparent;

}
.nav-item:hover{
  border-bottom: 1px solid #F97300;
  color: #F97300;
}
.active{
  border-bottom: 1px solid #F97300;
  color: #F97300;
  font-weight: bold;
}
.nav-button{
  font-size: 2rem;
  cursor: pointer;
  width: 100%;
  display: none;
}
.banner-education{
  font-size: 1rem;
  margin-bottom: 1rem;
}
.banner{
  padding-top: 5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  gap: 5rem;
  min-height: 90vh;
  align-items: center;
}
.left-banner{
  padding: 1rem 2rem;
  width: 30rem;
  height: 30rem;

}
  


.hello{
  text-align: left;
  font-size: 1.5rem;
  font-family: "Timmana", sans-serif;
  color: #F97300;
}
.banner-title{
  color: #E2DFD0;
  font-size: 5rem;
  text-align: start;
}
.typewritter{
  text-align: left;
  line-height: 5rem;
  color: #F97300;
  font-family: "Timmana", sans-serif;
  font-weight: 400;
  font-size: 4rem;
  font-style: normal;

}

.right-banner{
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* min-width: 30rem; */
  align-items: center;
}
.myphoto{
  width: 17rem;

  
}
.right-banner h2{
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  text-align: center;
  color: white;
}
.contact-links{
  margin-top: 1rem;
  font-size: 2rem;
  
}
.contact-links a{
  color: #F97300;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

#skills{
  padding-top: 5rem;
  width: 100%;
  color: white;

  
}
.skills-title{
  margin-bottom: 3rem;

}
.skills-container{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
  flex-wrap: wrap;

}
.skills-button{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* padding: 1.5rem 4rem ; */
  justify-content: center;

}
.skills-button>div{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.skills-button button{
  outline: none;
  margin: 1rem;
  width: 10rem;
  min-height: 3rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  padding: 0.5rem;
  border: 2px solid transparent;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  background-image: linear-gradient(to right,#252525,rgb(4, 4, 90));
}
.selected-button{
  border: 2px solid #F97300 !important;
}
.skills{
  height: 40vh;

  border: 1px solid black;
  min-width: 20rem;
}
.skill{

  background-color: #252525;
  margin: 1rem;
  padding: 1rem;
  border-radius: 10px;
}
.skill>h3{
  text-align: left;
  margin-bottom: 0.3rem;
}
.skill>div{
  height: 5px;
  border-radius: 3px;
  background-image: linear-gradient(to right,rgb(255, 255, 255),#F97300);
}
.project-section{
  padding-top: 10rem;
  margin: 0 2rem;
  
}

.project-cards{
  position: relative;
  padding-top: 5rem;
}

.project-container{
  display: flex;
  justify-content: space-between;
  max-width: 50rem;
  gap: 5rem;
  margin: auto;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10rem;
}
.project-container:nth-child(2n){
  flex-direction: row-reverse;
}
.project-desc-container{
  width: 18rem;
  text-align: left;
}
.project-date{
  text-align: center;
  font-size: 1.2rem;
  color: #F97300;
  font-weight: 600;
}
.project-desc{
  /* text-align: justify; */
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.project-tech{
  display: flex;
  gap:1rem;
  flex-wrap: wrap;
}
.tech{
  background: linear-gradient(to right, white,#F97300);
  padding: 0.4rem;
  border-radius: 10px;
  color: black;
  font-weight: 600;
}

.project-card{
  width: 17rem;
  background-color: #151515;
  padding: 1rem 0.7rem ;
  border-radius: 10px;

}
.project-card>img{
  width: 100%;
  border-radius: 10px 10px 0 0;
  margin-bottom: 2rem;
}
.project-name{
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: white;
  display: block;
  font-weight: 500;
  text-decoration: none;
}
.links{
  display: flex;
  justify-content: center;
  gap:2rem;
}
.links>a{
  font-size: 1.7rem;
  cursor: pointer;
  color: gray;
}
.scroll{
  height: 80%;
  position: absolute;
  left:50%;
  top:3%;
}
.project-navigation{
  position: absolute;
  height: 80%;
  width: 5px;
  background-color: #fff;
  top: 10%;
  
  
}
.project-navigation-scroll{
  height: 20rem;
  width: 5px;
  background-color: #F97300;
  position:sticky ;
  top:0;

}

.education-section{
  padding-top: 5rem;
  display: flex;
  flex-direction: column;

}

.institution{

  margin: 3rem 1rem;
  padding: 1rem;
  text-align: left;
  box-shadow: 2px 2px 6px gray;
}
.present{
  box-shadow: 2px 2px 6px #F97300;

}
.education-section>h2{
  margin-bottom: 3rem;
}
.education-details{
  width: auto;
}
.year{
  text-align: right;
  margin-bottom: 2rem;
}
.institute-name{
  font-weight: 600;
  line-height: 2rem;
  font-size: 1.2rem;
}
.grade{
  background: #F97300;
  padding: 0 0.5rem ;
  margin: 0.5rem;
  color: black;
  font-weight: 600;
}


.footer{
  margin-top: 5rem;
}
.resume{
  position: fixed;
  left: 1rem;
  top: 5rem;
  border: 1px solid white;
  border-radius: 10rem;
  background-color: #F97300 ;
  display: flex;
  width: 7rem;
  padding: 0.5rem;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  text-decoration: none;
  font-weight: bold;

}

.contact-form {
  max-width: 400px;
  margin: 10rem auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* background-color: white; */
}
.contact-form p{
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: black;
  background-color: #f9f9f9; /* Light background for inputs */
}

.contact-form textarea {
  resize: vertical;
  min-height: 100px;
}

.contact-form button {
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  background-color: #F97300; /* Vibrant orange */
  color: white;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #e76e00; /* Darker orange for hover effect */
}

.contact-form span {
  display: block;
  margin-top: 15px;
  /* color: black; */
  font-size: 14px;
  text-align: center;
}


@media only screen and (max-width: 640px) {
  .visible-none{
    display: none;
  }
  .navbar{
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem 0;
  }
  .nav-items{
    margin-right: 0;
    width: 100%;
    flex-direction: column;
    gap: 1.5rem;
   
  }
  .nav-item{
    padding: 0.5rem;
  }
  .nav-item:hover{
    background-color: black;
    border-bottom: 1px solid transparent;


  }
  
  .active{
    border-bottom: 1px solid transparent;

  }
  .nav-button{
    display: block;
  }
  .hello{
    font-size: 1rem;
  }
  .banner-title{
    font-size: 4rem;
  }
  .typewritter{
    font-size: 2rem;
    line-height: 3rem;

  }
  .left-banner{
    width: 30rem;
    height: 10rem;
  }
  #skills{
    margin-top: 7rem;
    
  }
  .project-section{
    margin-top:5rem ;
  }
  .project-container{
    flex-direction: row !important;

  }
  .skills-button button{
    width: 8rem;
  }
  
  .scroll{
    left: 100%;
  }
}